import React, { useState } from "react"

export default function HeadingBar (props) {

const [showCollections, setShowCollections] = useState()
const height = props.height || 'auto'
const margintop = props.height ? parseInt(props.height.substr(0,3)) / 2.5 + 'px' : 'auto'
const marginbottom = props.search ? '40px' : 'auto'


  return (
    <div className="heading-bar" style={{height:height}}>
    </div>
  )
}